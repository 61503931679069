import React from 'react';
import PropTypes from 'prop-types';

const Paragraph = ({ title, color }) => {
  return (
    <div>
      <h2 className={`font-alternate sm:text-lg md:text-lg lg:text-lg xl:text-lg ${color === 'grey' ? 'text-grey' : 'text-salmon'} `} >
        {title}
      </h2>
    </div>
  );
};

Paragraph.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Paragraph.defaultProps = {
  color: 'salmon',
};

export default Paragraph;
