import React from 'react';
import PropTypes from 'prop-types';

const H2Title = ({ title, color }) => {
  return (
    <div>
      <h2 className={`my-8 font-display sm:text-base md:text-base lg:text-lg xl:text-lg uppercase ${color === 'white' ? 'text-white' : 'text-black'} `} >
        {title}
      </h2>
    </div>
  );
};

H2Title.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

H2Title.defaultProps = {
  color: 'black',
};

export default H2Title;
