import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeSamples from '../components/homesamples';
import H2Title from '../components/h2title';
import Paragraph from '../components/paragraph';
import NewsletterMain from '../components/newslettermain';

import phone from '../images/phone_main.png';
import phone1 from '../images/phone1.png';
import phone2 from '../images/phone2.png';
import phone3 from '../images/phone3.png';
import logo from '../images/logo_baige.png';
import appstore from '../images/appstore.svg';
import googleplay from '../images/googleplay.svg';
import note from '../images/note.svg';
import settings from '../images/settings.svg';
import desktop from '../images/desktop.svg';
import piano from '../images/piano.svg';
import guitar from '../images/guitar.svg';

import '../style/styles.css';

const IndexPage = () => (
  <Layout>
    <SEO title="Get your music noted." />
    <div className="flex flex-col overflow-x-hidden">
      <div className="min-h-screen sm:mb-64 md:mb-2 max-w-full w-screen blacklinearbg sm:p-2 lg:p-10 flex sm:flex-col md:flex-col lg:flex-row xl:flex-row">
        <div className="flex flex-col mt-24 lg:ml-10 xl:ml-10 items-start justify-start lg:w-4/5 xl:w-4/5">
          <img
            className="h-32 mt-8"
            alt="Kodai: Audio to midi converter"
            src={logo}
          />
          <h1 className="tracking-widest font-display text-white sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl pt-8 pb-4 lg:w-4/5 xl:w-4/5">
            GET YOUR MUSIC NOTED.
          </h1>
          <div className="sm:w-full md:w-full lg:w-3/5 xl:w-3/5 my-4 pr-10">
            <Paragraph
              color="salmon"
              title="Transcribe your audio recordings to MIDI files using state of the art AI technology. Focus on your creative song-writing flow by saving time on music notation."
            />
          </div>
          <div className="flex flex-row mb-8">
            <img
              className="h-16 mr-4"
              alt="Kodai: Piano to midi converter"
              src={piano}
            />
            <img
              className="h-16"
              alt="Kodai: Guitar to midi converter"
              src={guitar}
            />
            <p className="ml-4 w-2/3 font-alternate sm:text-lg md:text-lg lg:text-lg xl:text-lg text-salmon">
              Convert your guitar and piano recordings to MIDI files with the help of machine learning.
            </p>
          </div>
          <div className="flex-row mt-4 items-start justify-start">
            <button
              type="button"
              onClick={() => {
                if (typeof window !== `undefined`) {
                  window.open(
                    'https://apps.apple.com/app/id1518032115',
                    '_blank',
                  );
                }
              }}
              className="sm:ml-0 md:ml-0 lg:mr-10 xl:mr-10"
            >
              <img
                className="sm:h-10 md:h-12"
                alt="Midi converter for ios"
                src={appstore}
              />
            </button>
            <button
              type="button"
              onClick={() => {
                if (typeof window !== `undefined`) {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.kodai.kodaiapp2',
                    '_blank',
                  );
                }
              }}
            >
              <img
                className="sm:h-10 md:h-12"
                alt="Midi converter for android"
                src={googleplay}
              />
            </button>
          </div>
        </div>
        <div className="flex pt-16 -ml-4 items-center w-screen sm:block md:block lg:hidden xl:hidden">
          <img className="w-full h-auto" alt="Kodai app" src={phone} />
        </div>
        <div className="pt-20 mr-20 mt-16 sm:hidden md:hidden lg:block xl:block w-halfscreen ">
          <img className="w-full h-auto" alt="Kodai app" src={phone} />
        </div>
      </div>
     {/* <NewsletterMain />*/}
      <div className="max-w-full sm:h-full lg:min-h-screen w-screen bg-salmon grid sm:p-2 lg:p-10 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 sm:mt-phone md:mt-phone">
        <div className="sm:my-10 md:my-10 lg:m-10 xl:m-10">
          <img className="h-8" alt="Audio to midi" src={note} />
          <H2Title
            color="black"
            title="use your phone to transcribe music recordings to midi."
          />
          <Paragraph
            color="grey"
            title="Turn your guitar and piano recordings into MIDI files and edit them with the included MIDIeditor. All you need is your phone."
          />
        </div>
        <div className="sm:my-10 md:my-10 lg:m-10 xl:m-10">
          <img className="h-8" alt="Collaborate with others" src={settings} />
          <H2Title
            color="black"
            title="Standard practice tools and interactive theory cheatsheet"
          />
          <Paragraph
            color="grey"
            title="There are small things that often come in handy. Metronome, tuner, interactive circle of fifths and a one octave touchscreen piano is included."
          />
        </div>
        <div className="sm:my-10 md:my-10 lg:m-10 xl:m-10">
          <img className="h-8" alt="Integrate into DAVs" src={desktop} />
          <H2Title
            color="black"
            title="finish up projects in an application of your choice."
          />
          <Paragraph
            color="grey"
            title="Kodai renders its results into the standard MIDI format, so you can continue to work with them in almost any digital audio workstation."
          />
        </div>
        <img
          className="sm:hidden md:hidden w-3/4 m-10 mb-phonedown"
          alt="Konvert audio to midi"
          src={phone1}
        />
        <img
          className="sm:hidden md:hidden w-3/4 m-10 mb-phonedown"
          alt="Collab and share"
          src={phone2}
        />
        <img
          className="sm:hidden md:hidden w-3/4 m-10 mb-phonedown"
          alt="Record what you play"
          src={phone3}
        />
      </div>
      <HomeSamples />
    </div>
  </Layout>
);

export default IndexPage;
