import React, { useRef, useState } from 'react';
import H2Title from './h2title';
import Paragraph from './paragraph';
import play from '../images/play.svg';
import stop from '../images/stop.svg';
import midi from '../images/midi.svg';
import wave from '../images/wave.svg';
import piano from '../images/piano.svg';
import kodai_ciao_midi from '../assets/kodai_ciao_midi.mp3';
import kodai_ciao_real from '../assets/kodai_ciao_real.mp3';
import '../style/styles.css';

const HomeSamples = ({ references }) => {
  let midiPlayer = useRef(null);
  let wavPlayer = useRef(null);
  const [wavPlaying, setWavPlaying] = useState(false);
  const [midiPlaying, setMidiPlaying] = useState(false);

  const playMidi = () => {
    if (!midiPlaying) midiPlayer.play();
    if (midiPlaying) midiPlayer.pause();
    setMidiPlaying(!midiPlaying);
  };
  const playWav = () => {
    if (!wavPlaying) wavPlayer.play();
    if (wavPlaying) wavPlayer.pause();
    setWavPlaying(!wavPlaying);
  };

  return (
    <div className="max-w-full sm:h-full lg:min-h-screen w-screen blacklinearbg p-10 pt-20 sm:flex-col xl:flex-row flex">
      <div className="flex flex-col sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
        <H2Title
          color="white"
          title="from recording to midi in minutes. with the help of ai."
        />
        <Paragraph
          color="salmon"
          title="Kodai is powered by Magenta, the state of the art AI technology in music processing. While transcribing the audio recordings to midi, the AI predicts the instrument, the pitch, and the rhythm with high accuracy. The result is ready in seconds."
        />
        <p className="font-alternate text-white my-8">
          The examples are actual files created with Kodai.
        </p>
      </div>
      <div className="flex flex-col sm:w-screen md:w-screen lg:w-1/3 xl:w-1/3 sm:items-start md:items-start lg:items-center xl:items-center">
        <div className="flex sm:flex-row-reverse md:flex-row-reverse lg:flex-col xl:flex-col">
          <audio
            ref={ip => {
              wavPlayer = ip;
            }}
          >
            <source src={kodai_ciao_real} type="audio/mp3" />
          </audio>
          <img
            className="sm:w-screen md:w-auto md:max-w-[100px] lg:w-auto xl:w-auto sm:h-auto md:h-auto lg:h-28 xl:h-28"
            alt="Play wav file"
            style={{ minWidth: '20vw', maxWidth: '80vw' }}
            src={midi}
          />
          <img
            onClick={() => playWav()}
            alt="Play wav file"
            src={wavPlaying ? stop : play}
            className="w-16 h-16 mx-auto mt-4"
          />
        </div>
        <p className="mx-8 my-8 font-display text-white text-lg uppercase sm:text-left md:text-left lg:text-center xl:text-center">
          Wav file recorded with a OnePlus 6 via kodai.
        </p>
        <p className="font-display text-white text-sm sm:text-left sm:ml-8 -mt-4 md:text-left lg:text-center xl:text-center mb-8">
          Bella Ciao - Recorded by Kiss Bors Máté
        </p>
      </div>
      <div className="flex flex-col sm:w-screen md:w-screen lg:w-1/3 xl:w-1/3 sm:items-start md:items-start lg:items-center xl:items-center">
        <div className="flex sm:flex-row-reverse md:flex-row-reverse lg:flex-col xl:flex-col">
          <audio
            ref={ip => {
              midiPlayer = ip;
            }}
          >
            <source src={kodai_ciao_midi} type="audio/mp3" />
          </audio>
          <img
            className="sm:w-screen md:w-auto md:max-w-[100px] lg:w-auto xl:w-auto sm:h-auto md:h-auto lg:h-28 xl:h-28"
            alt="Play midi file"
            style={{ minWidth: '20vw', maxWidth: '80vw' }}
            src={wave}
          />
          <img
            onClick={() => playMidi()}
            alt="Play converted midi file"
            src={midiPlaying ? stop : play}
            className="w-16 h-16 mx-auto mt-4"
          />
        </div>
        <p className="mx-8 my-8 font-display text-white text-lg uppercase sm:text-left md:text-left lg:text-center xl:text-center">
          midi file transcribed by AI powering kodai.
        </p>
      </div>
    </div>
  );
};

export default HomeSamples;
